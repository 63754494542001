import * as React from "react"
import { graphql } from "gatsby"
import get from "lodash/get"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Hero from "../../components/hero"
import "../../scss/pages/index.scss"

class PortalIndex extends React.Component {
  componentDidMount() {
  }
  render() {
    // Component Content

    const heroContent = get(this, "props.data.contentfulHero")
    const password = get(this, "props.data.allContentfulPortalPassword")

    return (
      <Layout bodyClass="portal">
        <Seo title="Retailer Portal" description="Use your SIMPAS-applied Solutions retailer login to find customizable promotional materials for your business." />
        <section className="padding--none">
          <Hero data={heroContent} password={password} />
        </section>
      </Layout>
    )
  }
}
export default PortalIndex

export const PortalIndexQuery = graphql`
  query portalPageQuery {
    allContentfulPortalPassword {
      nodes {
        id
        password
      }
    }
    contentfulHero(heroTitle: { eq: "Retailer Toolkit" }, login: { eq: true }) {
      id
      heroTitle
      heroSubtitle
      heroDescription {
        heroDescription
      }
      backgroundImage {
        fluid(maxWidth: 1800, background: "#000", quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      backgroundImageMobile {
        fluid(maxWidth: 640, background: "#000", quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      foregroundImage {
        file {
          url
        }
        description
      }
      modalLink
      modalButtonText
      login
    }
  }
`
